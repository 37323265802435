import React from "react"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "../utils/normalize.css"
import "../utils/css/screen.css"

const AboutPage = ({ data }, location) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout title={siteTitle}>
      <SEO title="Sobre" keywords={[`blog`, `gatsby`, `javascript`, `react`]} />

      <article
        className="post-content page-template no-image"
        style={{
          backgroundImage: "radial-gradient(#E4FFCE 5%,#E4FFCE 15%, white 60%)",
        }}
      >
        <div className="blog-body">
          <h1 style={{ marginTop: "100px" }}>Sobre</h1>

          <p style={{ fontSize: "1.25em" }}>
            A <b>Compartilhada</b> é uma plataforma que surgiu da necessidade de
            unir e disseminar tudo o que a comunidade criativa de{" "}
            <b>Belo Horizonte</b> já fez e tem feito em seus processos de
            criação e desenvolvimento artístico e cultural.
          </p>

          <p style={{ fontSize: "1.25em" }}>
            O que nos move é o desejo de ser fonte de inspiração para criadores
            e para nós mesmos, pois somos criativos e queremos provocar
            (re)conhecimentos, debates e fomentar ainda mais criatividade.
          </p>

          <p style={{ fontSize: "1.25em" }}>
            O projeto está sendo viabilizado pela{" "}
            <b>Lei Municipal de Incentivo à Cultura de BH</b> e tem patrocínio
            da Rock Content.
          </p>
          <h2 style={{ fontSize: "1.25em", marginTop: "50px" }}>
            <b>Equipe:</b>
          </h2>

          <p>
            <div
              style={{
                width: "50%",
                display: "inline-block",
                paddingTop: "20px",
              }}
            >
              <div style={{ fontSize: "1.25em", fontWeight: "bold" }}>
                Evoé:
              </div>
              <small style={{ fontSize: "1em" }}>Idealização</small>
            </div>

            <div
              style={{
                width: "50%",
                display: "inline-block",
                paddingTop: "20px",
              }}
            >
              <div style={{ fontSize: "1.25em", fontWeight: "bold" }}>
                Ruth Flôres:
              </div>
              <small style={{ fontSize: "1em" }}>
                Editora e criadora de conteúdo
              </small>
            </div>

            <div
              style={{
                width: "50%",
                display: "inline-block",
                paddingTop: "20px",
              }}
            >
              <div style={{ fontSize: "1.25em", fontWeight: "bold" }}>
                Bruna Kassab:
              </div>
              <small style={{ fontSize: "1em" }}>
                Direção criativa e criadora de conteúdo
              </small>
            </div>

            <div
              style={{
                width: "50%",
                display: "inline-block",
                paddingTop: "20px",
              }}
            >
              <div style={{ fontSize: "1.25em", fontWeight: "bold" }}>
                Luiza Firmato:
              </div>
              <small style={{ fontSize: "1em" }}>Executivo</small>
            </div>

            <div
              style={{
                width: "50%",
                display: "inline-block",
                paddingTop: "20px",
              }}
            >
              <div style={{ fontSize: "1.25em", fontWeight: "bold" }}>
                Robert Learmouth:
              </div>
              <small style={{ fontSize: "1em" }}>
                Designer e Desenvolvedor
              </small>
            </div>

            <div
              style={{
                width: "50%",
                display: "inline-block",
                paddingTop: "20px",
              }}
            >
              <div style={{ fontSize: "1.25em", fontWeight: "bold" }}>
                Carolina Spotorno:
              </div>
              <small style={{ fontSize: "1em" }}>Gestora</small>
            </div>

            <div
              style={{
                width: "50%",
                display: "inline-block",
                paddingTop: "20px",
              }}
            >
              <div style={{ fontSize: "1.25em", fontWeight: "bold" }}>
                Marcus Maia:
              </div>
              <small style={{ fontSize: "1em" }}>Designer</small>
            </div>
          </p>
        </div>
      </article>
    </Layout>
  )
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    benchAccounting: file(
      relativePath: { eq: "bench-accounting-49909-unsplash.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1360) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <AboutPage location={props.location} data={data} {...props} />
    )}
  />
)
